@use "sass:map";

$dur: 6s;
$ease-in: cubic-bezier(0.32,0,0.67,0);
$ease-out: cubic-bezier(0.33,1,0.68,1);
$ease-in-out: cubic-bezier(0.65,0,0.35,1);

// mixins
@mixin borders1-start() {
  border: {
    top-color: hsla(0,0%,0%,0.1);
    right-color: hsla(0,0%,0%,0.1);
    bottom-color: hsla(0,0%,100%,0.1);
    left-color: hsla(0,0%,100%,0.3);
  };
}
@mixin borders2-start() {
  border: {
    top-color: hsla(0,0%,0%,0.1);
    right-color: hsla(0,0%,100%,0.1);
    bottom-color: hsla(0,0%,100%,0.3);
    left-color: hsla(0,0%,0%,0.1);
  };
}
@mixin borders3-start() {
  border: {
    top-color: hsla(0,0%,100%,0.1);
    right-color: hsla(0,0%,100%,0.3);
    bottom-color: hsla(0,0%,0%,0.1);
    left-color: hsla(0,0%,0%,0.1);
  };
}
@mixin borders-end() {
  border: {
    top-color: hsla(0,0%,100%,0.3);
    right-color: hsla(0,0%,0%,0.1);
    bottom-color: hsla(0,0%,0%,0.1);
    left-color: hsla(0,0%,100%,0.1);
  };
};

$blocks: (
  (
    "color": var(--red),
    "x": 14,
    "y": 14,
    "w": 4,
    "h": 6,
    "areas": "x a" "x b" "c d",
    "transform1": translate(10em,0) rotate(90deg),
    "transform2": translate(14em,0) rotate(90deg),
    "transform3": translate(14em,0) rotate(0),
    "transform4": translate(14em,14em) rotate(0),
    "effect": true,
    "effect-type": 1
  ),
  (
    "color": var(--cyan),
    "x": 0,
    "y": 18,
    "w": 8,
    "h": 2,
    "areas": "a b c d",
    "transform1": translate(6em,0),
    "transform2": translate(0,0),
    "transform3": translate(0,2em),
    "transform4": translate(0,18em)
  ),
  (
    "color": var(--green),
    "x": 10,
    "y": 16,
    "w": 6,
    "h": 4,
    "areas": "x a b" "c d y",
    "transform1": translate(8em,0),
    "transform2": translate(10em,0),
    "transform3": translate(10em,2em),
    "transform4": translate(10em,16em)
  ),
  (
    "color": var(--yellow),
    "x": 2,
    "y": 14,
    "w": 4,
    "h": 4,
    "areas": "a b" "c d",
    "transform1": translate(10em,0),
    "transform2": translate(0,0),
    "transform3": translate(2em,0),
    "transform4": translate(2em,14em)
  ),
  (
    "color": var(--purple),
    "x": 8,
    "y": 14,
    "w": 4,
    "h": 6,
    "areas": "a x" "b c" "d y",
    "transform1": translate(10em,0) rotate(90deg),
    "transform2": translate(8em,0) rotate(90deg),
    "transform3": translate(8em,0) rotate(0),
    "transform4": translate(8em,14em) rotate(0),
    "effect": true,
    "effect-type": 1
  ),
  (
    "color": var(--red),
    "x": 8,
    "y": 12,
    "w": 6,
    "h": 4,
    "areas": "a b x" "y c d",
    "transform1": translate(8em,0),
    "transform2": translate(10em,0),
    "transform3": translate(8em,0),
    "transform4": translate(8em,12em)
  ),
  (
    "color": var(--orange),
    "x": 4,
    "y": 12,
    "w": 4,
    "h": 6,
    "areas": "a b" "x c" "x d",
    "transform1": translate(12em,0) rotate(90deg),
    "transform2": translate(4em,0) rotate(90deg),
    "transform3": translate(4em,0) rotate(0),
    "transform4": translate(4em,12em) rotate(0),
    "effect": true,
    "effect-type": 1
  ),
  (
    "color": var(--purple),
    "x": 12,
    "y": 12,
    "w": 6,
    "h": 4,
    "areas": "a b c" "x d y",
    "transform1": translate(10em,0) rotate(180deg),
    "transform2": translate(12em,0) rotate(180deg),
    "transform3": translate(12em,0) rotate(0),
    "transform4": translate(12em,12em) rotate(0),
    "effect": true,
    "effect-type": 2
  ),
  (
    "color": var(--blue),
    "x": 0,
    "y": 12,
    "w": 4,
    "h": 6,
    "areas": "a b" "c x" "d x",
    "transform1": translate(8em,0) rotate(-90deg),
    "transform2": translate(6em,0) rotate(0),
    "transform3": translate(0,0) rotate(0),
    "transform4": translate(0,12em) rotate(0),
    "effect": true,
    "effect-type": 3
  ),
  (
    "color": var(--cyan),
    "x": 18,
    "y": 12,
    "w": 2,
    "h": 8,
    "areas": "a" "b" "c" "d",
    "transform1": translate(10em,0) rotate(90deg),
    "transform2": translate(18em,0) rotate(0),
    "transform3": translate(18em,0) rotate(0),
    "transform4": translate(18em,12em) rotate(0),
    "effect": true,
    "effect-type": 4
  )
);

// styles
*,
*:before,
*:after {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  --hue: 223;
  --bg: hsl(var(--hue),10%,10%);
  --fg: hsl(var(--hue),10%,70%); 
  --red: #0d6efd; 
  --orange: #6c757d; 
  --yellow: #ffc107; 
  --green: #198754; 
  --cyan: #0dcaf0; 
  --blue: #5072df; 
  --purple: #7B40E4; 
  --trans-dur: 0.3s;
}
body {
  background-color: var(--bg);
  color: var(--fg);
  display: flex;
  font: 1em/1.5 sans-serif;
  height: 100vh;
  transition:
    background-color var(--trans-dur),
    color var(--trans-dur);
}
main {
  margin: auto;
  padding: 1.5em 0;
}

.tetris-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2); /* adjust for desired transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transition: opacity 2s ease-out; /* Updated to 2s for fade-out */
}

.tetris-hidden {
  opacity: 0;
  pointer-events: none;
}

.api-response {
  position: relative;
  z-index: 2;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  color: white;
}

.tetris {
  animation-name: bounce;
  position: relative;
  width: 20em; // doubled
  height: 21em; // doubled

  &:after {
    background-color: hsl(var(--hue),10%,70%);
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.5em;
    transition: background-color var(--trans-dur);
  }
  &,
  &__block,
  &__block-cell  {
    animation: {
      duration: $dur;
      timing-function: $ease-in-out;
      iteration-count: infinite;
    };
  }
  &__block {
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 2em 2em; // adjusted for doubling

    $letters: a b c d;
    &-cell {
      border: 0.25em solid hsla(0,0%,0%,0) {
        top-color: hsla(0,0%,100%,0.3);
        right-color: hsla(0,0%,0%,0.1);
        bottom-color: hsla(0,0%,0%,0.1);
        left-color: hsla(0,0%,100%,0.1);
      };
      @for $l from 1 through length($letters) {
        &:nth-child(#{$l}) {
          grid-area: nth($letters,$l);
        }
      }
    }

    @for $b from 1 through length($blocks) {
      $block: nth($blocks,$b);
      &--#{$b} {
        animation-name: block#{$b};
        grid-template: {
          areas: map.get($block,"areas");
          columns: repeat(map.get($block,"w"),1fr);
          rows: repeat(map.get($block,"h"),1fr);
        };
        width: map.get($block,"w") * 2em; // doubled
        height: map.get($block,"h") * 2em; // doubled
        transform: translate(map.get($block,"x") * 2em,map.get($block,"y") * 2em); // doubled
      }
      &--#{$b} &-cell {
        @if map.get($block,"effect") == true {
          animation-name: block#{$b}-borders;
        }
        background-color: map.get($block,"color");
      }
    }
  }
}
/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue),10%,10%);
    --fg: hsl(var(--hue),10%,90%);
  }
  .tetris {
    &:after {
      background-color: hsl(var(--hue),10%,30%);
    }
  }
}
/* Animations */
@keyframes dot-blink {
	0% {
	  opacity: 1;
	}
	20% {
	  opacity: 0;
	}
	40% {
	  opacity: 1;
	}
  }
  
  .dot {
	display: inline-block;
	animation: dot-blink 1s infinite;
	
	&:nth-child(2) {
	  animation-delay: 0.2s;
	}
	&:nth-child(3) {
	  animation-delay: 0.4s;
	}}
  
@keyframes bounce {
  from,
  96%,
  to {
    animation-timing-function: linear;
    transform: translate(0,0);
  }
  98% {
    animation-timing-function: $ease-out;
    transform: translate(0,1em);
  }
}
@for $b from 1 through length($blocks) {
  $block: nth($blocks,$b);
  $inc: 9.6%;
  // block movements
  @keyframes block#{$b} {
    from {
      @if $b > 1 {
        animation-timing-function: steps(1,end);
        visibility: hidden;
      }
      transform: map.get($block,"transform1");
    }
    @if $b > 1 {
      #{$inc * ($b - 1)}  {
        animation-timing-function: $ease-in-out;
        visibility: visible;
        transform: map.get($block,"transform1");
      }
    }
    #{$inc * ($b - 1) + ($inc / 3)} {
      transform: map.get($block,"transform2");
    }
    #{$inc * ($b - 1) + ($inc / 3) * 2} {
      animation-timing-function: $ease-in;
      transform: map.get($block,"transform3");
    }
    #{$inc * ($b - 1) + $inc},
    98% {
      animation-timing-function: $ease-out;
      opacity: 1;
      transform: map.get($block,"transform4");
    }
    to {
      opacity: 0;
      transform: map.get($block,"transform4");
    }
  }
  // lighting effects
  @if map.get($block,"effect") == true {
    @keyframes block#{$b}-borders {
      $effectType: map.get($block,"effect-type");

      @if $effectType == 1 {
        from,
        #{$inc * ($b - 1) + ($inc / 3)} {
          @include borders1-start();
        }
        #{$inc * ($b - 1) + ($inc / 3) * 2},
        to {
          @include borders-end();
        }
      } @else if $effectType == 2 {
        from,
        #{$inc * ($b - 1) + ($inc / 3)} {
          @include borders2-start();
        }
        #{$inc * ($b - 1) + ($inc / 3) * 2},
        to {
          @include borders-end();
        }
      } @else if $effectType == 3 {
        @if $b == 1 {
          from {
            @include borders3-start();
          }
        } @else {
          from,
          #{$inc * ($b - 1)}  {
            @include borders3-start();
          }
        }
        #{$inc * ($b - 1) + ($inc / 3)},
        to {
          @include borders-end();
        }
      } @else if $effectType == 4 {
        @if $b == 1 {
          from {
            @include borders1-start();
          }
        } @else {
          from,
          #{$inc * ($b - 1)}  {
            @include borders1-start();
          }
        }
        #{$inc * ($b - 1) + ($inc / 3)},
        to {
          @include borders-end();
        }
      }
    }
  }
}
